import { createSlice } from "@reduxjs/toolkit";

export const SettingSlice = createSlice({
    name: "setting",
    initialState: {
        setting: null,
    },
    reducers: {
        setsetting: (state, action) => {
            state.setting = action.payload;
        },
        clearsetting: (state, action) => {
            state.setting = null;
        }
    }
})

export const { setsetting, clearsetting } = SettingSlice.actions;

export default SettingSlice.reducer;