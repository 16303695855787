import { createSlice } from "@reduxjs/toolkit";

export const SelectedPropertySlice = createSlice({
    name: "selectedProperty",
    initialState: {
        selectedProperty: null,
    },
    reducers: {
        setselectedproperty: (state, action) => {
            state.selectedProperty = action.payload;
        },
        clearselectedproperty: (state, action) => {
            state.selectedProperty = null;
        }
    }
})

export const { setselectedproperty, clearselectedproperty } = SelectedPropertySlice.actions;

export default SelectedPropertySlice.reducer;