import * as React from "react";
import { useSelector } from "react-redux";
import { setagentfilter } from "../features/AgentFilterSlice";
import { useDispatch } from "react-redux";
import axios from 'axios';
import { variables } from '../Variables';
import { MultiSelect } from "react-multi-select-component";

import closeIcon from '../assets/images/cross-close.svg';
import bedIcon from '../assets/images/bed-duo.svg';
import showerIcon from '../assets/images/shower-duo.svg';
import liftIcon from '../assets/images/lift-duo.svg';
import locationPin from '../assets/images/location-pin.svg';
import placeholderLarge from '../assets/images/prop-placeholder-lg.jpg';
import agentBlack from '../assets/images/agents-black.svg';

const { useState, useRef, useEffect } = React;

export const NewEvent = ({ handleClose, showMessage, callBackToUpdateCalendarFilter }) => {
    const [whiseAgents, setWhiseAgents] = useState([]);
    const [selectedAgentDropdown, setSelectedAgentDropdown] = useState([]);
    const [isAgentConfirmed, setIsAgentConfirmed] = useState(false);

    const whiseUserToken = useSelector(state => state.whiseUser.whiseToken);
    const status = useSelector(state => state.statusFilter.statusFilter);
    const reduxAgent = useSelector(state => state.agentFilter.agentFilter);
    const property = useSelector(state => state.selectedProperty.selectedProperty);
    const dispatch = useDispatch();
    const reduxClientToken = useSelector(state => state.whiseClientToken.whiseClientToken);

    const changeHandlerAgent = (e) => {
        let option = e.target.selectedOptions;
        if (option[0].value != "") {
            let item = JSON.parse(option[0].dataset.agent);
            let agent = [{ label: item.name, value: item.id, agentDetail: item }];
            setSelectedAgentDropdown(agent);
        }
        else {
            setSelectedAgentDropdown([]);
        }

        //setSelectedAgentDropdown(e);
    }

    const getAgents = async () => {
        let url = "https://api.whise.eu/v1/admin/representatives/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${reduxClientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, {}, config)
            .then(response => {
                setWhiseAgents(response.data.representatives);
            })
            .catch(error => {
                showMessage("error", error);
            });
    }

    const handlerNextConfirmAgent = (e) => {
        setIsAgentConfirmed(true);
    }

    const moveBackToAgentSelection = () => {
        setIsAgentConfirmed(false);
    }

    const handlerConfirmAgentFilter = (e) => {
        dispatch(setagentfilter(selectedAgentDropdown));
        callBackToUpdateCalendarFilter(selectedAgentDropdown);
        handleClose();
    }

    useEffect(() => {
        if (whiseUserToken && reduxClientToken != null) {
            getAgents();
        }
    }, [whiseUserToken])

    return (
        <>
            <div className="d-flex justify-content-between mb-4">
                <p className="form-title"> New Event </p>
                <img src={closeIcon} onClick={handleClose} className="cursor-pointer" />
            </div>
            <div className="d-flex form-container form-container-expanded room-info mb-4">
                <div className="modal-img-container bg-black bor w-50">
                    {
                        property != undefined && property.pictures != undefined && property.pictures.length > 0
                            ?
                            <img src={property.pictures[0].urlLarge} className="property-img" />
                            :
                            <img src={placeholderLarge} className="property-img" />

                    }
                </div>
                <div className="ms-2 pt-3">
                    <p className="mb-0 property-name ">
                        {
                            property != null ?
                                property.name
                                :
                                "Property Name"
                        }
                    </p>
                    <div className
                        ="prop-address d-flex align-items-center mt-2 mb-4">
                        <img src={locationPin} className="me-2" />
                        {
                            property != null ?
                                <>
                                    <p className="mb-0">
                                        {
                                            (property.address == undefined ? "" : property.address) + " " +
                                            (property.number == undefined ? "" : property.number) + ", " +
                                            (property.zip == undefined ? "" : property.zip) + " " +
                                            (property.city == undefined ? "" : property.city)
                                        }
                                    </p>
                                </>
                                :
                                <p className="mb-0">Address</p>
                        }
                    </div>
                    <div className="row prop-detail-row">
                        <div className="col-4 text-center border-end">
                            <img src={bedIcon} className="me-2 small-img-modal" />
                            <span className="smallImgIcon-Modal-info">
                                {
                                    property != null ?
                                        property.bedRooms
                                        :
                                        0
                                }
                            </span>
                        </div>
                        <div className="col-4 text-center border-end">
                            <img src={showerIcon} className="me-2 small-img-modal" />
                            <span className="smallImgIcon-Modal-info">
                                {
                                    property != null ?
                                        property.bathRooms
                                        :
                                        0
                                }
                            </span>
                        </div>
                        <div className="col-4 text-center d-flex align-items-end">
                            <img src={liftIcon} className="me-2 small-img-modal" />
                            {
                                property != null ?
                                    <span className="smallImgIcon-Modal-info">{property.area}m<sup>2</sup></span>
                                    :
                                    <span className="smallImgIcon-Modal-info">0</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                isAgentConfirmed == false ?
                    <>
                        <div className="form-group selectModal-container mb-4">
                            <label className="form-group-label property-name mt-0" htmlFor="agent">Select the agent who will make the visit<span className="text-danger">*</span>
                            </label>
                            <select className="form-select" onChange={changeHandlerAgent} value={selectedAgentDropdown.length > 0 ? selectedAgentDropdown[0].value : ""}>
                                <option value="">Select Agent</option>
                                {
                                    whiseAgents.length > 0 ? whiseAgents.map(item => {
                                        return (
                                            <option value={item.id} data-agent={JSON.stringify(item)} >{item.firstName + " " + item.name}</option>
                                        )
                                    })
                                        :
                                        <></>
                                }
                            </select>
                            {/*<MultiSelect*/}
                            {/*    id="agent"*/}
                            {/*    value={selectedAgentDropdown != null && selectedAgentDropdown.length > 0 ? selectedAgentDropdown : []}*/}
                            {/*    options={() => {*/}
                            {/*        if (whiseAgents.length > 0) {*/}
                            {/*            return whiseAgents.map((item, index) => {*/}
                            {/*                return { label: item.name, value: item.id, agentDetail: item }*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*        else {*/}
                            {/*            return []*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*    onChange={changeHandlerAgent}*/}
                            {/*    labelledBy="Select an option"*/}
                            {/*/>*/}
                        </div>
                        {
                            selectedAgentDropdown == "" ?
                                <button id="btnNextAfterAgent" className="site-btn site-btn-padd w-100 disabled" disabled>Next</button>
                                :
                                <button id="btnNextAfterAgent" className="site-btn site-btn-padd w-100" onClick={handlerNextConfirmAgent}>Next</button>
                        }

                    </>
                    :
                    <>
                        <div className="d-flex justify-content-between mb-4">
                            <div className="d-flex align-self-baseline">
                                <img src={agentBlack} />
                                <p className="property-name m-0 ms-3" >Agent</p>
                            </div>
                            <div className=" text-end">
                                {
                                    selectedAgentDropdown.length > 0 ? selectedAgentDropdown.map(item => {
                                        return (
                                            <>
                                                <p className="property-name m-0" >{item.label}</p>
                                                <p className="prop-address justify-content-end mb-0">Id:<span >{item.value}</span></p>
                                            </>
                                        )
                                    })
                                        :
                                        <>
                                            <p className="property-name m-0" ></p>
                                            <p className="prop-address justify-content-end mb-0">Id:<span ></span></p>
                                        </>
                                }
                            </div>
                        </div>
                        <div className="d-flex">
                            <button className="filter-btn site-btn-padd border-dark color-dark w-100 me-3" onClick={moveBackToAgentSelection}>Back</button>
                            <button className="site-btn site-btn-padd w-100" onClick={handlerConfirmAgentFilter}>Confirm</button>
                        </div>
                    </>
            }
        </>
    )
}
