import { createSlice } from "@reduxjs/toolkit";

export const AgentFilterSlice = createSlice({
    name: "agentFilter",
    initialState: {
        agentFilter: null,
    },
    reducers: {
        setagentfilter: (state, action) => {
            state.agentFilter = action.payload;
        },
        clearagentfilter: (state, action) => {
            state.agentFilter = null;
        }
    }
})

export const { setagentfilter, clearagentfilter } = AgentFilterSlice.actions;

export default AgentFilterSlice.reducer;