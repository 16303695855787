import { createSlice } from "@reduxjs/toolkit";

export const StatusFilterSlice = createSlice({
    name: "statusFilter",
    initialState: {
        StatusFilter: null,
    },
    reducers: {
        setstatusfilter: (state, action) => {
            state.statusFilter = action.payload;
        },
        clearstatusfilter: (state, action) => {
            state.statusFilter = null;
        }
    }
})

export const { setstatusfilter, clearstatusfilter } = StatusFilterSlice.actions;

export default StatusFilterSlice.reducer;