import { createSlice } from "@reduxjs/toolkit";

export const SidebarCollapseSlice = createSlice({
    name: "sidebarCollapsed",
    initialState: {
        sidebarCollapsed: false,
    },
    reducers: {
        setSidebarCollapsed: (state, action) => {
            state.sidebarCollapsed = action.payload;
        },
        clearSidebarCollapsed: (state, action) => {
            state.sidebarCollapsed = false;
        }
    }
})

export const { setSidebarCollapsed, clearSidebarCollapsed } = SidebarCollapseSlice.actions;

export default SidebarCollapseSlice.reducer;