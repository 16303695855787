import * as React from "react";
import * as ReactDOM from "react-dom";

export const Agents = ({ showMessage }) => {
    return (
        <>
            <div className="row">
                <div className="col-12 py-4"></div>
            </div>
            <div className="row">
                <div className="col-12 px-5 py-3">
                    Agents
                </div>
            </div>
        </>
    )
}