import { createSlice } from "@reduxjs/toolkit";

export const CalendarSelectedViewSlice = createSlice({
    name: "calendarView",
    initialState: {
        calendarView: null,
    },
    reducers: {
        setCalendarView: (state, action) => {
            state.calendarView = action.payload;
        },
        clearCalendarView: (state, action) => {
            state.calendarView = null;
        }
    }
})

export const { setCalendarView, clearCalendarView } = CalendarSelectedViewSlice.actions;

export default CalendarSelectedViewSlice.reducer;