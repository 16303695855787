import * as React from "react";
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { seteventdetail, cleareventdetail } from "../features/CreateEventDetailSlice";

import closeIcon from '../assets/images/cross-close.svg';
import officeIcon from '../assets/images/office_address_icon.svg';
import propIcon from '../assets/images/prop_address_icon.svg';
import { AvailableHours } from "../eventforms/AvailableHours";
import { SetupInvitee } from "../eventforms/SetupInvitee";
import { ConfirmSlot } from "../eventforms/ConfirmSlot";
import warning from '../assets/images/warning.svg';

const { useState, useEffect } = React;

export const NewEventDetails = ({ handleClose, whiseAgents, saveNewWhiseEvent }) => {
    const [calendarActions, setCalendarActions] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [addressType, setAddressType] = useState("property");
    const [eventType, setEventType] = useState("");
    const [eventTypeId, setEventTypeId] = useState("");

    const whiseUserToken = useSelector(state => state.whiseUser.whiseToken);
    const reduxStatus = useSelector(state => state.statusFilter.statusFilter);
    const reduxagent = useSelector(state => state.agentFilter.agentFilter);
    const reduxEventDetail = useSelector(state => state.eventDetail.eventDetail);
    const reduxProperty = useSelector(state => state.selectedProperty.selectedProperty);
    const reduxSetting = useSelector(state => state.setting.setting);
    const actions = useSelector(state => state.calendarActions.calendarActions);

    const dispatch = useDispatch();

    const changeHandlerAddressType = (e) => {
        let address = e.target.id;
        if (address == "propertyAddress") {
            setAddressType("property")
        }
        else {
            setAddressType("office")
        }
    }

    const setNewEventDetail = (e) => {
        let duration = document.querySelector("#duration").value;
        let isAnyFieldEmpty = false;
        if (duration == "") {
            document.querySelector("#duration").style.borderColor = "red";
            isAnyFieldEmpty = true;
        }

        if (isAnyFieldEmpty == true) {
            alert("fill the required fields")
            return
        }

        let eventDetail = {
            event: {},
            availableHours: null,
            invitee: null,
            confirmEventSlot: null
        };
        
        eventDetail.event.EventType = eventTypeId;
        eventDetail.event.EventDuration = duration;
        eventDetail.event.EventAddress = addressType;
     
        dispatch(seteventdetail(eventDetail));

        setCurrentStep(current => current + 1);
    }

    const resetFieldBorder = (e) => {
        e.target.removeAttribute("style");
    }

    const callbackSetEventStep = () => {
        setCurrentStep(current => current + 1);
    }

    const callbackSetInviteDetail = (obj) => {

        let eventDetail = {
            event: reduxEventDetail.event,
            availableHours: reduxEventDetail.availableHours,
            invitee: obj,
            confirmEventSlot: null
        };
        dispatch(seteventdetail(eventDetail));

        setCurrentStep(current => current + 1);
    }

    const clickHandlerAvailableHoursBack = () => {
        setCurrentStep(current => current - 1);
    }

    const clickHandlerSetupInviteeBack = () => {
        setCurrentStep(current => current - 1);
    }

    useEffect(() => {
        if (calendarActions.length > 0) {
            if (reduxProperty.purpose.id == 1) {
                var type = calendarActions.filter(item => {
                    return item.id == reduxSetting.visitToSaleFreeSlot
                })
                setEventType("(To Sale)");
                setEventTypeId(type[0].id);
            }
            else if (reduxProperty.purpose.id == 2) {
                var type = calendarActions.filter(item => {
                    return item.id == reduxSetting.visitToRentFreeSlot
                })
                setEventType("(To Rent)");
                setEventTypeId(type[0].id);
            }
        }
    }, [calendarActions])

    useEffect(() => {
        if (actions != null && actions != undefined) {
            setCalendarActions(actions);
        }
    }, [actions])

    return (
        <>
            {
                currentStep == 1 ?
                    <>
                        <div className="d-flex justify-content-between mb-3">
                            <ul className="form-steps">
                                <li className="step-bar active"></li>
                                <li className="step-bar"></li>
                                <li className="step-bar"></li>
                                <li className="step-bar"></li>
                            </ul>
                            <img src={closeIcon} onClick={handleClose} className="cursor-pointer" />
                        </div>
                        <div>
                            <p className="form-title mb-2"> New Event </p>
                        </div>
                        <div className="warning mb-2">
                            <img src={warning} className="me-3" />
                            <p className="mb-0">
                                Only future events will be catered.
                            </p>
                        </div>
                        <div>
                            <p className="subtitle mb-4">Event Details</p>
                        </div>
                        <div className="form-group">
                            <label className="form-group-label mt-0" htmlFor="eventType">Event type<span>*</span></label>
                            <div className="form-group-label">
                                <span className="subtitle text-dark me-1">Visit:</span>{eventType}
                            </div>
                        </div>
                        <div className="form-group mb-4">
                            <label className="form-group-label mt-0" htmlFor="duration">Duration<span>*</span></label>
                            <select className="form-select" id="duration" onChange={resetFieldBorder}>
                                <option value="">Select Duration</option>
                                <option value="10">10 min</option>
                                <option value="15">15 min</option>
                                <option value="20">20 min</option>
                                <option value="25">25 min</option>
                                <option value="30">30 min</option>
                                <option value="35">35 min</option>
                                <option value="40">40 min</option>
                                <option value="45">45 min</option>
                                <option value="50">50 min</option>
                                <option value="55">55 min</option>
                                <option value="60">60 min</option>
                            </select>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <input type="radio" name="radios" id="propertyAddress" className="invisible-radio" onChange={changeHandlerAddressType} checked />
                            <label htmlFor="propertyAddress" className="radio-label">
                                <div className="styled-radio">
                                    <div className="m-auto">
                                        <img src={propIcon} />
                                    </div>
                                    <div>Property Address</div>
                                </div>
                            </label>

                            <input type="radio" name="radios" id="officeAddress" className="invisible-radio" onChange={changeHandlerAddressType} />
                            <label htmlFor="officeAddress" className="radio-label">
                                <div className="styled-radio">
                                    <div className="m-auto">
                                        <img src={officeIcon} />
                                    </div>
                                    <div>Office Address</div>
                                </div>
                            </label>
                        </div>
                        <div className="d-flex">
                            <button type="button" className="filter-btn site-btn-padd border-dark color-dark w-100 me-3" onClick={handleClose}>Back</button>
                            <button type="button" className="site-btn site-btn-padd w-100" onClick={setNewEventDetail}>Next</button>
                        </div>
                    </>
                    :
                    currentStep == 2 ?
                        <AvailableHours handleClose={handleClose} callBackClickHandlerNext={callbackSetEventStep} backButtonClickHandler={clickHandlerAvailableHoursBack} />
                        :
                        currentStep == 3 ?
                            <SetupInvitee handleClose={handleClose} callBackClickHandlerNext={callbackSetInviteDetail} backButtonClickHandler={clickHandlerAvailableHoursBack} />
                            :
                            <ConfirmSlot handleClose={handleClose} saveNewWhiseEvent={saveNewWhiseEvent} calendarActions={calendarActions} whiseAgents={whiseAgents} />
            }
        </>
    )
}