import { Calendar } from "./components/Calendar";
import { Properties } from "./components/Properties";
import { Agents } from "./components/Agents";
import { Settings } from "./components/Settings";
import { Home } from "./components/Home";
import { Booking } from "./components/Booking";
import { faHouse, faCalendar, faHouseUser, faUsers, faCog } from '@fortawesome/free-solid-svg-icons';

const AppRoutes = [
    //{
    //    index: true,
    //    path: "/",
    //    element: <Home />,
    //    icon: "Home",
    //    name: "Home"
    //},
    {
        index: true,
        path: "/",
        element: <Calendar />,
        icon: "calendar",
        name: "Calendar"
    },
    {
        path: '/properties',
        element: <Properties />,
        icon: "properties",
        name: "Properties"
    },
    {
        path: '/agents',
        element: <Agents />,
        icon: "agents",
        name: "Agents",
    },
    {
        path: '/settings',
        element: <Settings />,
        icon: "settings",
        name: "Settings"
    }
];

export default AppRoutes;
