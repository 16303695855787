import { createSlice } from "@reduxjs/toolkit";

export const ClientTokenSlice = createSlice({
    name: "whiseClientToken",
    initialState: {
        whiseClientToken: null,
    },
    reducers: {
        setclienttoken: (state, action) => {
            state.whiseClientToken = action.payload;
        },
        clearclienttoken: (state, action) => {
            state.whiseClientToken = null;
        }
    }
})

export const { setclienttoken, clearclienttoken } = ClientTokenSlice.actions;

export default ClientTokenSlice.reducer;